$bg-white: #fdfdfd;
$link-color: #222288;

$h1-font-size: 2.5rem;
$h2-font-size: 2rem;
$h5-font-size: 1.25rem;

body {
	line-height: 1.5;
}
h1, h2, h3, h4, h5, h6 {
	margin-top: 0;
	margin-bottom: .5rem;
    font-family: inherit;
    font-weight: 500;
    line-height: 1.2;
}
h1 { font-size: $h1-font-size; }
h2 { font-size: $h2-font-size; }
// h3 { font-size: $h3-font-size; }
// h4 { font-size: $h4-font-size; }
h5 { font-size: $h5-font-size; }
// h6 { font-size: $h6-font-size; }

#resume-app {
	font-family: 'Roboto', sans-serif;
	font-size: 12px;
	justify-content: center;
	display: flex;

	.resume-split-screen {
		width: 100vw;
		display: flex;
		background-color: $bg-white;
	}

	.resume {
		box-shadow: 0px 0px 9px 0px rgba(0,0,0,0.75);
		background-color: $bg-white;
		margin: 10px;
		padding: 27px 32px;
		// width: 1275px;
		// height: 1650px;
		width: 816px;
		height: 1056px;
	}

	@media print {
		.resume {
			box-shadow: none;
			margin: 0px;
			padding: 2px 32px;
		}
	}

	.resume-editor {
		&__logo {
			color: white;
			border: 1px solid white;
			margin: 10px;
		}
		&__json {
			width: 50%;
			position: relative;
			height: 100vh;
			display: flex;
			flex-flow: column;
		}

		nav {
			background: rgb(39, 40, 34);
			height: 50px;
			.resume-editor__toolbar {
				max-width: unset;
				margin: unset;
				padding: 0 5px;
				.dropdown {
					z-index: 10;
					width: 97px;
					ul.menu {
						width: 225px;
						display: none;
						background: rgb(39, 40, 34);
						
						.menu-item {
							display: flex;
							align-items: center;
							padding: 10px;
							justify-content: space-between;
							border-bottom: 1px solid #efefef;

							span {
								color: #efefef;
								font-size: 0.9rem;
								width: 70px;

								&.selected-color {
									width: 30px;
									height: 30px;
									border: 1px solid white;
								}
							}
							input {
								width: 93px;
								height: 35px;
							}
						}
					}
					&:hover {
						ul {
							display: block;
						}
					}
				}

				.nav-logo {
					a {
						color: #efefef;
					}
				}
	
				.nav-links {
					a {
						cursor: pointer;
						color: #efefef;
						font-size: 0.9rem;
		
						&:hover {
							background-color: #555;
							opacity: 1;
						}
					}
				}
			}
		}
	}
}
